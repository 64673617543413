import React from "react"

import { ScaleIcon, LightningBoltIcon, AcademicCapIcon, ShieldCheckIcon } from '@heroicons/react/outline'


const features = [
{
    name: 'Compétences',
    icon: AcademicCapIcon,
},
{
    name: 'Indépendance',
    icon: ScaleIcon,
},
{
    name: "Réactivité",
    icon: LightningBoltIcon,
},
{
    name: 'Responsabilité',
    icon: ShieldCheckIcon,
},
]



export default function Reassurance() {
  return (

                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Pourquoi choisir un expert du réseau Fissuration.fr ?
                          </h3>
                        </div>

                        <div className="mt-10">
                          <dl className="space-y-10 md:space-y-0 md:grid md:gap-x-8 md:gap-y-10">
                            {features.map((feature) => (
                              <div key={feature.name} className="relative">
                                <dt>
                                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white">
                                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                                  </div>
                                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900  py-2">{feature.name}</p>
                                </dt>
                              </div>
                            ))}
                          </dl>
                        </div>
                      </div>
                    </div>

        )
    }